<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标题:">
                    <el-input class="s-input" v-model="queryParams.title" placeholder="请输入标题" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="上线" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">添加邀请码商品</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="id" prop="id" width="100" align="center"></el-table-column>
                <el-table-column label="logo图标" align="center" width="80" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" fit="cover" :class="`column${scope.$index}`" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="标题" prop="title" width="200" align="center"></el-table-column>
                <el-table-column label="描述" prop="desc" align="center" width="280"></el-table-column>
                <el-table-column label="数量" prop="number" width="150" align="center"></el-table-column>
                <el-table-column label="价格" prop="price" width="150" align="center"></el-table-column>

                <el-table-column label="会员等级" prop="ty" width="150" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">店主</el-tag>
                        <el-tag type="success" v-if="scope.row.ty === 2">店员</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="有效期" prop="time" width="100" align="center"></el-table-column>
                <el-table-column label="状态" prop="pay_state" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="50%" v-if="addVisible" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <!-- 图片上传 -->
                <el-form-item label="logo图标" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入logo图标"></el-input>
                    <el-button @click.prevent="choiceImg">选择图片</el-button>
                    <el-upload class="avatar-uploader up" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸100*100像素</span>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <el-input v-model="form.desc" placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input v-model="form.price" placeholder="请输入价格"></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="number">
                    <el-input v-model="form.number" placeholder="请输入数量"></el-input>
                </el-form-item>
                <el-form-item label="会员等级" prop="ty">
                    <el-select v-model="form.ty" placeholder="请选择会员等级">
                        <el-option label="店主" v-bind:value="1"></el-option>
                        <el-option label="店员" v-bind:value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="有效期" prop="time">
                    <el-input v-model="form.time" placeholder="输入时间"></el-input>
                    <span style="font-size:14px; margin-left:10px;">天</span>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import Picture from '@/components/Picture';
import { deal } from '@/common/main'
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            ty: null,
            menuList: ['功能', '邀请码商品'],
            loading: false,
            header: {},
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,
                countType: '',
                num: '',
            },
            pageInfo: {},
            brandList: [],
            addVisible: false,
            title: '添加邀请码商品',
            rules: {
                logo: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                title: [{
                    required: true,
                    message: '标题不能为空',
                    trigger: 'blur'
                }],
                desc: [{
                    required: true,
                    message: '描述不能为空',
                    trigger: 'blur'
                }],
                price: [{
                    required: true,
                    message: '价格不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                number: [{
                    required: true,
                    message: '数量不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[1-9]\d*$',
                    message: "只能输入非零的正整数"
                }],
                ty: [{
                    required: true,
                    message: '等级不能为空',
                    trigger: ['blur', 'change']
                }],
                time: [{
                    required: true,
                    message: '有效期不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[1-9]\d*$',
                    message: "只能输入非零的正整数"
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },

            mode: 'add',
            form: {

            },
        }
    },
    created() {
        this.loadData();
        // this.getBrandList()
    },
    methods: {
        //获取表格列表
        // getBrandList() {
        //   request.get("/plug/invitation/list", {}).then((ret) => {
        //     if (ret.code == 200) {
        //       this.brandList = ret.data;
        //     } else {
        //       this.$message.error(ret.msg);
        //     }
        //   })
        // },
        //状态
        state(row, state) {
            if (state == 0) {
                this.confirmContent = '确定是否禁用?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            } else {
                this.confirmContent = '确定是否启用?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            }

        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        loadData() {
            this.loading = true;
            //邀请码列表
            request.get('/plug/invitation/goods/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        add(id, index) {
            this.mode = 'add';
            // if (this.$refs.form) {
            //     this.$refs['form'].resetFields()
            // }
            this.form = { state: 2 }
            this.addVisible = true
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            // //处理时
            this.form = Object.assign({}, this.pageInfo.list[index]);
            this.form.time = this.form.time + ''
            this.form.number = this.form.time + ''
            this.form.price = this.form.price + ''
            this.addVisible = true;
        },
        delete(id, index) {

        },
        changeCount(num) {
            this.form.num = num
        },
        changeDatePicker: function () {
            let vue = this
            if (vue.pickerDatad != null) {
                vue.form.stime = vue.pickerDatad[0]
                vue.form.etime = vue.pickerDatad[1]
            } else {
                vue.form.stime = null
                vue.form.etime = null
            }
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //   this.form.num = this.form.countType == 1 ? 1 : this.form.num;
                        // this.form.brand_ids = this.form.brand_id;
                        var url = this.mode == 'add' ? '/plug/invitation/goods/add' : '/plug/invitation/goods/edit';
                        this.form.stime = deal.timestamp(this.form.stime);
                        this.form.etime = deal.timestamp(this.form.etime);
                        if (this.mode === 'add') {
                        }
                        //处理时间
                        console.log(5555, this.form)
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/plug/invitation/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'logo', url);
        },
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
